<script lang="ts">
	import type { ProductForCustomer } from "../../../../core/schema/Product";
	import type { ProductAmountPerCategory } from "../../../../core/schema/ProductAmountPerCategory";
	import { slugify } from "../../../../core/utils/slugify";
	import Link from "../Link.svelte";
	import ProductCard from "./ProductCard.svelte";

	export let products: ProductForCustomer[];
	export let maxProductsPerCategory: ProductAmountPerCategory;
	export let headline = "Nejoblíbenější";
	export let category = "dorty";
</script>

<div>
	<h2 class="decorative-headline">{headline}</h2>
	<div class="product-card-wrapper mt-6 lg:mt-2">
		{#each products as product, index (product.id)}
			<ProductCard {product} {index} {maxProductsPerCategory} />
		{/each}
	</div>
	<Link class="mx-auto mt-10 !px-14" href="/{slugify(category)}" asButton variant="secondary">
		Zobrazit další
		<span class="lowercase">{category}</span>
	</Link>
</div>
